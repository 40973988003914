.container.education_container {
  width: 50%;
  padding-bottom: 4rem;
  
}

.education_picture {
  width: 100%;
  aspect-ratio: 0/1;
  overflow: hidden;
  border-radius: 1rem ;
  margin: 0 auto 1rem;
  border: 0.4rem solid var(--color-primary-variant);
}

.education {
    background: var(--color-bg-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}

.education_review{
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 100%;
    margin: 0.8rem auto 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet{
background: var(--color-primary);
}

/* ======= MEDIA QUERIES (MEDIUM DEVICES) =======*/
@media screen and (max-width: 1024px) {
    .container.education_container{
        width: 80%;
    }
  }
  
  /* ======= MEDIA QUERIES (SMALL DEVICES) =======*/
  @media screen and (max-width: 600px) {
    .container.education_container{
        width: var(--container-width-sm);
    }

    .education_review{
        width: var(--container-width-sm);
    }
  }