header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header_container {
  text-align: center;
  height: 100%;
  position: relative;
}

/*======= CTA =======*/
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/*=======Header Socials=======*/

.header_socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0rem;
  bottom: 3rem;
}

.header_socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background-color: var(--color-primary);
}

/*======= ME =======*/

.me {
  
  background: linear-gradient(var(--color-primary), transparent);
  
  width: 22rem;
  height: 30rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  border-radius: 1rem 1rem ;
  overflow: hidden;
  padding: 5rem 1.5rem 1.5rem 1.5rem;
 
}

.me:hover{
  background-image: url("/src/assets/sunset.jpeg");
  background-size: 125%;
  width: 22rem;
  height: 30rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  border-radius: 1rem 1rem 1rem 1rem;
  overflow: hidden;
  padding: 5rem 1.5rem 1.5rem 1.5rem;
}

/*======= Scroll Down =======*/

.scroll_down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
  
}




/* ======= MEDIA QUERIES (MEDIUM DEVICES) =======*/
@media screen and (max-width: 1024px) {
  header {
    height: 100vh;
  }
  .header_container{
  text-align: center;
  height: 100%;
  width: 100%;
  position: relative;
  }
 .me{
  width: 22rem;
  height: 30rem;
 }
}

/* ======= MEDIA QUERIES (SMALL DEVICES) =======*/
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }
.me {

  width: 200px;
  height: 200px;
  border-radius: 100px 100px 100px 100px;
  position: absolute;
  left: calc(70% - 11rem);
}

.me > img{
  position: absolute;
  top: -25px;
  left: 0px;
  height: 250px;
  width: 250px;
}

.me:hover {
  width: 200px;
  height: 200px;
  border-radius: 100px 100px 100px 100px;
  position: absolute;
  left: calc(70% - 11rem);
}

  .header_socials,
  .scroll_down {
    display: none;
  }
}
