.experience_frontend {
  width: 100%;
  height: 700px;
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 5px;
  
}

.experience_container {
  display: grid;
  margin: -2rem 2rem;
  grid-template-columns: 1fr;
  gap: 2rem;
  
}

.experience_container > div {
  background: var(--color-bg-variant);
  padding: 2.4rem 5rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.experience_container > div:hover {
  background:transparent;
  border-color: var(--color-primary);
  cursor: default;
}

.experience_container > div h2 {
  text-align: center;
  margin-bottom: 1rem;
  color: var(--color-primary);
}




/* ======= MEDIA QUERIES (MEDIUM DEVICES) =======*/
@media screen and (max-width: 1024px) {


  .experience_frontend{
    width: 100%;
  }
  .experience_container > div {
    width: 100%;
  }
 
}

/* ======= MEDIA QUERIES (SMALL DEVICES) =======*/
@media screen and (max-width: 600px) {
  .experience_container {
    width: 95%;
    margin: 0 auto;
    gap: 0;
  }

  .experience_frontend{
    grid-template-columns: 1fr;
    width: 100%;
    margin: 0 auto;
    gap: 0;
  }

}

